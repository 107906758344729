import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./MainHeader.css";
import logo from "../../assets/images/logo.svg";

const MainHeader = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const switchOff = () => setClick(false);

  return (
    <header>
      <Link to={"/"}>
        <img src={logo} alt="I am Tony Green" className="logo" />
      </Link>
      <nav>
        <ul className={click ? "active" : ""}>
          <li>
            <NavLink to="/" exact onClick={switchOff}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/mywork" onClick={switchOff}>
              My Work
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={switchOff}>
              About
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/mywork/thanx-recognition-app" onClick={switchOff}>
              Thanx
            </NavLink>
          </li> */}
          <li>
            <a href="mailto:hello@iamtonygreen.com" className="contact-me">
              Contact Me
            </a>
          </li>
        </ul>
        <button
          className={click ? "hamburger active" : "hamburger"}
          onClick={handleClick}
        >
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </button>
      </nav>
    </header>
  );
};

export default MainHeader;
