import "./Footer.css";
import behance from "../../assets/images/behance-white.png";
import linkedin from "../../assets/images/linkedin-white.png";

const Footer = () => {
  const CurrentDate = new Date();
  const Year = CurrentDate.getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-space-around">
          <div className="footer-block footer-weblinks col-sm-12 col-lg-3 offset-lg-1">
            <div className="footer-block-label">Find me on the web...</div>
            <a href="https://www.behance.net/hellomynameistony">
              <img src={behance} alt="" />
            </a>
            <a href="https://www.linkedin.com/in/tonygreenuk/">
              <img src={linkedin} alt="" />
            </a>
          </div>
          <div className="footer-block footer-contact col-sm-12 col-lg-6 offset-lg-1">
            <div className="footer-block-label">Or contact me directly at</div>
            <a href="mailto:hello@iamtonygreen.com">hello@iamtonygreen.com</a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center copyright">
            © Tony Green {Year}. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
